import React, { useContext, useState, useEffect } from 'react'
import { Container, Flex, Box, css, Button } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import { Text, Heading } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import HeroBlock04 from '../Block04/Block04'
import HeroBlock05 from '../Block05/Block05'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import envelopeIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-envelope.svg'
import { ModalContext, EmailContext } from '@solid-ui-components/Modal'
import theme from '@solid-ui-theme'
import { Formik } from 'formik';
import * as Yup from 'yup'
import { trackActionSegment, REGISTRATION_STARTED } from '../../../../../../site/segment/segment'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { blockedEmails } from './blockedEmails'

const styles = {
  form: {
    position: `relative`
  },
  buttonsWrapper: {
    position: ['relative', 'absolute'],
    top: ['7px', '8px'],
    right: ['8px', '15px'],
    display: `inline`,
    flexWrap: `no-wrap`,
    justifyContent: `center`
  }
}

const customButtons = [
  {
    text: 'ASSINAR AGORA',
    type: 'PAGE',
    variant: 'black',
    target: '_blank',
    link: 'https://buy.stripe.com/fZeeXLh10cvFfdu5kC'
  }
]

const legalDeptButtons = [
  {
    text: 'QUERO TESTAR EM MEU DEPARTAMENTO JURÍDICO',
    type: 'MODAL',
    variant: 'black',
    link: 'contactmodal'
  }
]

const HeroBlock01 = ({
  content: { text = [], images, buttons, form },
  reverse, isLegalDept
}) => {
  const { setActiveModal } = useContext(ModalContext)
  const { setEmail } = useContext(EmailContext)
  const [emailState, setEmailState] = useState('')

  // useEffect(() => {
  //   if (window) {
  //     window.wistiaIframeShim = false
  //   }
  // }, [])

  const handleTrackStartTrial = (values) => {
    let email = values.email;
    if (!blockedEmails.includes(email)) {
      trackActionSegment(REGISTRATION_STARTED, { "CTA": 1, "Email": email })
    }
  }

  const submitForm = formValues => {
    setEmail(formValues.email)
    setActiveModal('contactmodal')
  }

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
  }) => {
    return (
      <form
        css={css(styles.form)}
        onSubmit={handleSubmit}
        noValidate>
        <Box variant='forms.row'>
          <Box variant='forms.compact' sx={errors?.email ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
            <FormInput
              icon={{ "src": envelopeIcon, "color": "alpha" }}
              type='EMAIL'
              name={'email'}
              id={'email'}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              value={values.email}
              placeholder={{ 'text': 'Insira seu email' }}
            />
          </Box>
        </Box>
        <Box sx={{ textAlign: `center` }}>
          <Box sx={styles.buttonsWrapper}>
            <Button sx={{ padding: ['5px 15px', '2px 10px'] }} type='submit' variant='dark' onClick={() => { handleTrackStartTrial(values) }}>TESTE GRÁTIS</Button>
          </Box>
        </Box>
      </form>
    )
  }

  return (
    <Container>
      <Flex
        sx={{
          alignItems: [null, null, null, `center`],
          boxSizing: 'border-box',
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            null,
            null,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        <Box
          sx={{
            flexBasis: [null, null, `3/5`, `4/5`],
            [reverse ? 'ml' : 'mr']: [null, null, null, 5],
            position: `relative`,
            textAlign: `center`
          }}
        >
          <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <div className="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%', }}>
              <iframe src="https://fast.wistia.net/embed/iframe/ishlborsa8?seo=false&videoFoam=true" title="JUITRimor Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" className="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%">
              </iframe>
            </div>
          </div>
          {/* <div class="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <div class="wistia_responsive_wrapper" style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%', }}>
              <div class="wistia_embed wistia_async_ishlborsa8 seo=false videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>
                &nbsp;
              </div>
            </div>
          </div> */}
          {/* <ReactCompareSlider
            handle={<ReactCompareSliderHandle buttonStyle={{ color: '#f9b122', backgroundColor: '#2d3537' }} linesStyle={{ color: '#f9b122' }} />}
            itemOne={<HeroBlock05 />}
            itemTwo={<HeroBlock04 />}
          /> */}
        </Box>
        <Box
          sx={{
            flexBasis: [`4/5`, `3/5`],
            textAlign: [`center`, null, null, `left`]
          }}
        >
          <Reveal effect='fadeInDown'>
            <ContentText content={text} />
            <Text
              variant='p'
              color='white'
              sx={{ fontSize: '1.5rem', fontWeight: 500, lineHeight: '1.7rem', marginTop: '15px' }}>
              Pesquisas com Jurimetria e Inteligência Artificial para entender perfis decisórios e criar teses mais assertivas.
            </Text>
          </Reveal>
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <>
              {isLegalDept ? (
                <ContentButtons content={legalDeptButtons} />
              ) : (
                <Formik
                  initialValues={{ email: emailState }}
                  onSubmit={submitForm}
                  render={renderForm}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Por favor, insira um e-mail válido')
                      .required('Nós precisamos do seu email para liberar seu acesso a plataforma')
                  })}
                />
              )}
              {/* <ContentButtons content={customButtons} /> */}
            </>
          </Reveal>
        </Box>
      </Flex>
    </Container>
  )
}

export default HeroBlock01
