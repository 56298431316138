import React, { useState, useEffect } from 'react'
import { Container, Flex, Box, Badge, Link } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import axios from 'axios'
import { trackActionSegment, ACCESSED_BLOG } from '../../../../../../site/segment/segment'

import { FaRegClock } from 'react-icons/fa'

const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 300,
    maxWidth: [`none`, null, null, 500],
    cursor: `pointer`,
    p: 3
  },
  card: {
    overflow: `hidden`,
    height: `full`,
    margin: '0'
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `auto`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  }
}

const BlogBlock01 = () => {

  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios.get('https://blog-da-juit.ghost.io/ghost/api/v3/content/posts/?key=da41bd4f495bf6e13fb5e194dc&include=tags,authors')
      .then((res => {
        const lastPosts = res.data.posts.slice(0, 3)
        const lastFormattedPosts = lastPosts.map((post) => {
          return ({
            "container": {
              "variant": "cards.interactive",
              "link": {
                "type": "PAGE",
                "target": "_blank",
                "link": post.url
              }
            },
            "text": [{
              "text": post.tags[0].name,
              "color": "alphaDark"
            },
            {
              "text": post.title,
              "color": "omegaDarker",
              "variant": "h5"
            },
            {
              "text": post.excerpt
            },
            {
              "text": post.primary_author.name,
              "color": "omegaDark"
            },
            {
              "text": new Date(post.published_at).toLocaleDateString('pt-br')
            },
            {
              "text": `${post.reading_time} min`
            }
            ],
            "avatar": {
              "src": post.primary_author.profile_image,
              "alt": "Juit"
            },
            "images": [{
              "src": post.feature_image,
              "alt": post.slug
            }]
          })
        })
        setPosts(lastFormattedPosts)
      }))
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const contents = {
    "text": [{
      "text": "Últimos artigos do nosso blog",
      "variant": "h2"
    },
    {
      "text": "Acompanhe nossas publicações sobre direito e tecnologia, inovação, jurimetria, inteligência artificial, visual law, produtividade e muito mais.",
      "variant": "medium"
    }
    ]
  }

  const { text, buttons } = contents

  const collection = posts

  return (
    <Container>
      <Box sx={{ textAlign: `center` }}>
        <ContentText content={text} />
      </Box>
      <Divider />
      {collection && (
        <Reveal effect='fadeIn'>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -3 }}>
            {collection.map(
              ({ container, text, images, avatar, buttons }, index) => (
                <Box key={`item-${index}`} sx={styles.wrapper}>
                  <ContentContainer
                    onClick={() => trackActionSegment(ACCESSED_BLOG, { "Título": text[1].text })}
                    content={container}
                    variant='cards.interactive'
                    sx={styles.card}
                  >
                    <Flex as='article' sx={styles.content}>
                      {/* Image */}
                      <Box sx={{ flex: [0, 0], m: 0, mb: [null, null, null, 0] }}>
                        <Box sx={styles.imageWrapper}>
                          <img
                            style={{ width: '100%', height: '200px', overflow: 'hidden' }}
                            src={images[0].src}
                          />
                          {/* <ContentImages
                            content={{ images }}
                            sx={styles.image}
                            imageEffect='fadeIn'
                          /> */}
                        </Box>
                      </Box>
                      <Box sx={styles.body}>
                        {/* Category */}
                        {text?.[0]?.text && (
                          <Box sx={{ display: `inline-block` }}>
                            <Box mb='3'>
                              <Badge
                                variant='tag'
                                sx={{ bg: `alphaLighter` }}
                                color={text[0]?.color}
                              >
                                {text[0].text}
                              </Badge>
                            </Box>
                          </Box>
                        )}
                        {/* Title */}
                        <ContentText
                          content={text?.[1]}
                          sx={{ flex: [0, 0, `auto`] }}
                        />
                        {/* Excerpt */}
                        <ContentText
                          content={text?.[2]}
                          variant='small'
                          sx={{
                            flex: `auto`, mb: 3
                          }}
                        />
                        {/* Footer */}
                        <Box sx={{ variant: `text.small` }}>
                          <Flex sx={styles.footerWrapper}>
                            <img src={avatar.src} style={
                              {
                                width: '42px',
                                height: 'auto',
                                bg: `omegaLighter`,
                                borderRadius: `100%`,
                                borderStyle: `solid`,
                                borderWidth: `md`,
                                borderColor: `omegaLighter`,
                                boxSizing: `content-box`,
                                img: {
                                  objectPosition: 'top center !important'
                                }
                              }
                            } />
                            {/* <ContentImages
                              content={{ images: [avatar] }}
                              sx={styles.avatar}
                              imageEffect='fadeIn'
                            /> */}
                            <Flex sx={styles.postInfo}>
                              {/* Author */}
                              <ContentText
                                content={text?.[3]}
                                sx={{
                                  display: `inline-block`,
                                  flexBasis: `full`
                                }}
                              >
                                <Link color={text?.[3]?.color}>
                                  <strong>{text?.[3]?.text}</strong>
                                </Link>
                              </ContentText>
                              {/* Info */}
                              <Flex sx={{ alignItems: `center` }}>
                                <ContentText
                                  content={text?.[4]}
                                  sx={{ display: `inline-block` }}
                                  mr='2'
                                >
                                  {text?.[4]?.textGroup?.[0]}
                                </ContentText>
                                {text?.[5] && <FaRegClock />}
                                <ContentText
                                  content={text?.[5]}
                                  sx={{ display: `inline-block` }}
                                  ml='2'
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                        {buttons && (
                          <>
                            <Divider space={2} />
                            <ContentButtons content={buttons} />
                          </>
                        )}
                      </Box>
                    </Flex>
                  </ContentContainer>
                </Box>
              )
            )}
          </Flex>
        </Reveal>
      )}
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
        </>
      )}
    </Container>
  )
}



export default BlogBlock01
