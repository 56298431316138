import React from 'react'
import { Container, Flex, Box, Text, Link } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import { StaticImage } from "gatsby-plugin-image"
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './style.css'
import { trackActionSegment, NEWS_CLICKED } from '../../../../../site/segment/segment'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const content = {
  "text": [{
    "text": "Na Mídia",
    "variant": "h2",
  }
  ]
}

const NaMidia = () => {

  const { text } = content

  return (
    <Container>
      <ContentText content={text} sx={{ textAlign: 'center', marginBottom: '2rem !important' }} />
      <Carousel
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={2000}
        responsive={responsive}>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "GLOBO" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://oglobo.globo.com/economia/tecnologia/ringue-digital-empresas-disputam-palavra-chave-do-google-na-justica-25382449'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/oglobo.jpg'
                  alt="O Globo"
                  placeholder="O Globo"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “JUIT faz levamento e mostra que já existem 505 processos de empresas que disputam palavra-chave do Google na justiça.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Folha" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www1.folha.uol.com.br/mercado/2022/02/links-patrocinados-no-google-sao-campo-de-batalha-entre-empresas.shtml'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/folha_sp.png'
                  alt="Folha"
                  placeholder="Folha"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Com a JUIT, foi possível levantar para a Folha a quantidade de decisões envolvendo links patrocinados nos últimos anos.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Folha" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www1.folha.uol.com.br/mercado/2021/07/justica-ja-tem-600-decisoes-envolvendo-lei-de-protecao-de-dados.shtml'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/folha_sp.png'
                  alt="Folha"
                  placeholder="Folha"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Com levantamento da JUIT a Folha de São Paulo obteve o número de decisões envolvendo a lei de proteção de dados.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Estadao" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://politica.estadao.com.br/blogs/fausto-macedo/lgpd-preve-bloqueio-de-banco-de-dados-alem-de-multas/'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/estadao.jpg'
                  alt="Estadao"
                  placeholder="Estadao"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Com o JUIT Rimor foi possível verificar a influência de temas, como LGPD, no judiciário brasileiro.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Valor" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://valor.globo.com/publicacoes/suplementos/noticia/2021/10/20/cybertechs-criam-ferramentas-para-impedir-fraudes.ghtml'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/valor.png'
                  alt="Valor"
                  placeholder="Valor"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Após levantamento da JUIT, o Valo Econômico pode verificar a quantidade de decisões sobre LGPD e as principais dessas sentenças.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Metropoles" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www.metropoles.com/brasil/economia-br/apos-um-ano-em-vigor-lgpd-ja-embasou-mais-de-mil-sentencas-na-justica'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/metropoles.png'
                  alt="Metropoles"
                  placeholder="Metropoles"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Com a JUIT foi possível entender a quantidade de decisões sobre LGPD em seu primeiro ano de vigência.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "CNN" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://youtu.be/NNcrRO_7eok'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/cnn-brasil-logo.png'
                  alt="CNN"
                  placeholder="CNN"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Reconhecida por sua inovação no meio jurídico, a JUIT atrai olhares de investidores mesmo em meio a crise global.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "FGV" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://direitosp.fgv.br/noticia/solucao-de-analise-de-jurisprudencia-escolhida-como-legaltech-brasileira-mais-inovadora-vent'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/FGV.png'
                  alt="FGV"
                  placeholder="FGV"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “JUIT escolhida como a legal tech brasileira mais inovadora no Venture Day em São Paulo.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "AASP" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www.aasp.org.br/em-pauta/uma-ponte-para-startups-e-advocacia-que-buscam-solucoes-inovadoras/'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/logo-aasp.png'
                  alt="AASP"
                  placeholder="AASP"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “JUIT é considerada uma das startups mais promissoras do mercado de Legaltechs pela Demo Day e Venture Day da AASP”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "C6" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://medium.com/@C6bank/conhe%C3%A7a-as-seis-startups-selecionadas-para-a-2%C2%AA-turma-do-opp-3492380a172f'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/c6-logo.png'
                  alt="C6 Bank"
                  placeholder="C6 Bank"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “Prometendo busca assertiva simples a JUIT foi selecionada para a segunda fase do programa de aceleração do C6 Bank.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "IE LAW" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www.ie.edu/law-school/news-events/news/sao-paulo-legaltech-venture-day-ecosystem-actors-startups/'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/ie-logo.png'
                  alt="IE"
                  placeholder="IE"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “O JUIT Rimor é um software jurídico focado em facilitar o dia a dia do advogado(a) com tecnologias modernas.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Jornal do Comércio" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://www.jornaldocomercio.com/_conteudo/especiais/jornal_da_lei/2020/02/726216-pegando-carona-com-as-startups.html'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/jornal-logo.png'
                  alt="Jornal do Comércio"
                  placeholder="Jornal do Comércio"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “O JUIT Rimor é a solução para advogados encontrar jurisprudência de diversos tribunais e tomar decisões baseadas em dados.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "Law Innovation" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://lawinnovation.com.br/juit-oferece-plataforma-de-jurimetria-por-assinatura-mensal/'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/law-logo.png'
                  alt="Law Innovation"
                  placeholder="Law Innovation"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “O JUIT Rimor é o melhor caminho para que advogados tenham entendimento de como os tribunais julgam determinada matéria.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "FreeLaw" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://open.spotify.com/episode/0SwaLFhDQSxpi92TBCa8ya'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/freelaw-logo.png'
                  alt="FreeLaw"
                  placeholder="FreeLaw"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  “A plataforma que possibilita aos advogados(as) ganharem mais dinheiro usando a jurimetria, conheça o JUIT Rimor.”
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
        <Container variant='cards.paper' sx={{ width: '80%', minHeight: '300px', margin: '0', padding: '8%' }}>
          <Link
            onClick={() => trackActionSegment(NEWS_CLICKED, { "Link": "NEO4J" })}
            sx={{ textDecoration: 'none', color: 'inherit' }}
            href='https://blog.juit.io/juit-premiada-nos-eua-por-modelar-dados-nao-estruturados-judiciario-brasileiro/'
            target='_blank'
            rel='noreferrer'>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '210px' }}>
              <Box sx={{ width: 'auto', maxHeight: '140px', maxWidth: '150px' }}>
                <StaticImage
                  src='./companies/neo-logo.png'
                  alt="Neo4j"
                  placeholder="Neo4j"
                  layout="constrained"
                />
              </Box>
              <Box>
                <Text variant='p' sx={{ textAlign: 'justify', height: '60%' }} mt={4}>
                  "Mudando a prática do direito no Brasil, a JUIT, foi reconhecida internacionalmente com GraphConnect Awards da Neo4j."
                </Text>
              </Box>
            </Flex>
          </Link>
        </Container>
      </Carousel>
    </Container>
  )
}

export default NaMidia
