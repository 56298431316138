/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import logomarca from './assets/logomarca-juit-zebra.svg'

export default {
  heroContainer: {
    position: `relative`,
    py: [1, 3],
    '::before': {
      content: `" "`,
      width: `110%`,
      height: ['900px', `1000px`],
      position: `absolute`,
      top: `-270px`,
      right: [0, null, null, `33.3%`],
      transform: [`none`, null, null, `skew(-23deg, 0deg) rotate(-10deg)`],
      zIndex: -1,
      borderRadius: `xl`,
      background: t => `linear-gradient(
           ${t.colors.alpha} 60%,
           ${t.colors.beta} 100%
         )`,
      backgroundSize: `100%`,
      backgroundPosition: `650px bottom`
    },
    '::after': {
      content: `" "`,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -45%)`,
      zIndex: -1,
      size: 'full',
      maxWidth: 600,
      maxHeight: 600,
      background: `url(${logomarca}) no-repeat center center`,
      backgroundSize: `contain`,
      opacity: 0.1
    }
  },
  featuresContainer: {
    position: `relative`,
    overflow: [`hidden`, `unset`],
    '::before': {
      content: `" "`,
      width: `100%`,
      height: [`80%`, null, `140%`],
      position: `absolute`,
      top: [`5%`, null, `15%`],
      left: `60%`,
      zIndex: -1,
      borderRadius: `xl`,
      transform: `skew(23deg, 0deg) rotate(-10deg)`,
      background: t => `linear-gradient(
         ${t.colors.alpha} 40%,
         ${t.colors.beta} 100%
       )`,
      backgroundSize: `100%`,
      backgroundPosition: `650px bottom`
    },
    '::after': {
      display: [`none`, `block`],
      content: `" "`,
      position: `absolute`,
      top: `40%`,
      left: `80%`,
      transform: `translate(-50%, 0)`,
      zIndex: -1,
      size: '600px',
      background: `url(${logomarca}) no-repeat center center`,
      backgroundSize: `contain`,
      filter: `brightness(60%) sepia(100) saturate(100) hue-rotate(25deg)`,
      opacity: 0.1
    }
  },
  tabsContainer: {
    position: `relative`,
    py: [4, 5],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: 0,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `linear-gradient(
         180deg,
         #f7f9fe 0%,
         #f4f4f8 100%
       )`
    }
  },
  socialProofContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `100%`,
      height: `100%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -2,
      bg: `#F6F7FA`
    }
  }
}
