export const blockedEmails = [
	'joaomarcelo@juit.io',
	'john@juit.io',
	'johnnatan.santos@juit.io',
	'joao.junior@juit.io',
	'joaov7.1996@gmail.com',
	'john.sql@gmail.com',
	'jooaojb@gmail.com',
	'nathalia.delgado@juit.io',
	'nathalia.juarez40@gmail.com',
	'nathalia.delgado@direitosbc.br',
	'deoclides.agn@gmail.com',
	'contato@juit.io',
	'deoclides@juit.io',
	'johnnatan.santos@outlook.com',
	'contatojohnnatan@gmail.com',
	'teste@juit.io',
	'xuxa@xuxacompany.com.br',
	'johnnatan.xuxa.teste@hotmail.com'
];