import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Divider from '@solid-ui-components/Divider';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';
import Header from '@solid-ui-blocks/Header/Block01';
import Tabs from '@solid-ui-components/Tabs';
import Hero from '@solid-ui-blocks/Hero/Block01';
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02';
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05';
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01';
import Features from '@solid-ui-blocks/Features/Block02';
import Process from '@solid-ui-blocks/Features/Block03';
import Companies from '@solid-ui-blocks/Companies/Block01';
import NaMidia from '@solid-ui-blocks/NaMidia';
import Stats from '@solid-ui-blocks/Stats/Block01';
import Blog from '@solid-ui-blocks/Blog/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import NewsletterInput from '@solid-ui-blocks/NewsletterInput';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from './_theme';
import styles from './_styles';
import { Helmet } from 'react-helmet';
import OneSignal from 'react-onesignal';
import CustomServices from '@solid-ui-blocks/Features/CustomServices';

const IndexPage = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    useEffect(() => {
        OneSignal.init({
            appId: '338d0c3f-09d0-46e2-96d9-98f0aaf54d7a',
        });
    }, []);

    return (
        <>
            <Layout theme={theme} {...props}>
                <Helmet>
                    {/* <!-- Primary Meta Tags --> */}
                    <title>Jurisprudência ideal em pouco tempo | JUIT Rimor</title>
                    <meta name="title" content="Jurisprudência ideal em pouco tempo | JUIT Rimor" />
                    <meta
                        name="description"
                        content="Buscar jurisprudência nunca foi tão fácil. Pesquisas com Jurimetria e Inteligência Artificial para entender perfis decisórios e criar teses mais assertivas."
                    />
                    <meta
                        name="keywords"
                        content="jurisprudência, jurimetria, software jurimetria, pesquisa jurídica, inteligência artificial, direito, busca jurisprudencial, lawtech, legaltech, direito e tecnologia"
                    />
                    <meta name="robots" content="index, follow" />
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="language" content="pt-br" />
                    <meta name="revisit-after" content="7 days" />
                    {/* <!-- Open Graph / Facebook --> */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://juit.io/" />
                    <meta property="og:title" content="Jurisprudência ideal em pouco tempo | JUIT Rimor" />
                    <meta
                        property="og:description"
                        content="Buscar jurisprudência nunca foi tão fácil. Pesquisas com Jurimetria e Inteligência Artificial para entender perfis decisórios e criar teses mais assertivas."
                    />
                    <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />
                    {/* <!-- Twitter --> */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://juit.io/" />
                    <meta property="twitter:title" content="Jurisprudência ideal em pouco tempo | JUIT Rimor" />
                    <meta
                        property="twitter:description"
                        content="Buscar jurisprudência nunca foi tão fácil. Pesquisas com Jurimetria e Inteligência Artificial para entender perfis decisórios e criar teses mais assertivas."
                    />
                    <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />
                    <meta name="facebook-domain-verification" content="bfyr96mfy2d3bbkrtyrcyjreutoq5c" />
                    <link rel="apple-touch-icon" sizes="180x180" href="../../../site/content/assets/flexiblocks/icons/favIcons/apple-icon-180x180.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-16x16.png" />
                    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                    <script id="pixel-script-poptin" src="https://cdn.popt.in/pixel.js?id=93963828aa058" async="true"></script>
                    <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
                </Helmet>
                <ContactModal />
                <Header content={content['header-light']} menuJustify="space-around" />
                <Divider space="5" />
                <Divider space="5" />
                <Container variant="full" sx={styles.heroContainer}>
                    <Hero content={content['hero']} reverse />
                </Container>
                <Divider space="5" />
                <Divider space="5" />
                <Companies content={content['companies']} disableBackground />
                <div id="pesquisa3em1"></div>
                <Divider space="5" />
                <Container variant="wide" sx={styles.tabsContainer}>
                    <Tabs space={4} variant="pill" className="3em1tabs">
                        <FeatureOne content={content['tab-feature-one']} paddingLeft={'10px'} />
                        <FeatureTwo content={content['tab-feature-two']} />
                        <FeatureThree content={content['tab-feature-three']} />
                    </Tabs>
                </Container>
                <div id="funcionalidades"></div>
                <Divider space="5" />
                <Container variant="full" sx={styles.featuresContainer}>
                    <Features content={content['features']} />
                </Container>
                <Divider space="5" />
                <Divider space="5" />
                <Container variant="narrow">
                    <Stats content={content['stats']} />
                </Container>
                <Divider space="5" />
                <Divider space="5" />
                <Process content={content['process']} />
                <Divider space="5" />
                <Divider space="5" />
                <CustomServices content={content['services']} />
                <Divider space="5" />
                <NaMidia />
                <Divider space="5" />
                <Divider space="5" />
                <Blog content={content['latest-blogs']} />
                <Divider space="5" />
                <NewsletterInput />
                <Divider space="5" />
                <Footer content={content['footer']} />
            </Layout>
        </>
    );
};

export const query = graphql`
    query homepageSiteBlockContent {
        allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;
export default IndexPage;
