import React from 'react';
import { Container, Flex, Box, css } from 'theme-ui';
import Divider from '@solid-ui-components/Divider';
import Reveal from '@solid-ui-components/Reveal';
import ListItem from '@solid-ui-components/ListItem';
import ContentContainer from '@solid-ui-components/ContentContainer';
import ContentText from '@solid-ui-components/ContentText';
import Icon from '@solid-ui-components/ContentIcon';
import ContentButtons from '@solid-ui-components/ContentButtons';
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent';

const styles = {
    listItem: {
        minWidth: ['100%', '50%'],
        p: 3,
    },
    itemDescription: {
        flexGrow: 1,
        order: [1, null, 0],
        mb: 3,
    },
};

const CustomServices = ({ content: { text, collection }, paddingLeft }) => (
    <Container as={Reveal}>
        <Box sx={{ textAlign: `center` }}>
            <ContentText content={text[0]} />
        </Box>
        {collection && (
            <>
                <Divider />
                <Flex sx={{ flexWrap: `wrap`, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }} m={-3}>
                    {collection.map(({ text, icon, collection, buttons, container }, index) => (
                        <Reveal key={`item-${index}`} effect="fadeInGrow" delay={0.15 * (index + 1)} css={css(styles.listItem)}>
                            <ContentContainer
                                content={container}
                                variant="cards.interactive"
                                sx={{ height: `full`, p: 4, minWidth: '100%', '&:hover': { background: 'white' } }}>
                                <Icon content={icon} size="lg" mr="3" mb="3" />
                                <ContentText content={text?.[0]} />
                            </ContentContainer>
                        </Reveal>
                    ))}
                </Flex>
            </>
        )}
        <Box sx={{ textAlign: `center`, mt: 4 }}>
            <ContentText content={text[1]} />
        </Box>
    </Container>
);

export default WithDefaultContent(CustomServices);
